<template>
    <div
        :class="[$style.SkeletonLoader, $style._wave, {[$style._absolute]: absolute} ]"
        :style="computedStyle"
    >
    </div>
</template>

<script>
import { convertToUnit } from '~/components/ui/utils/helpers';

export default {
    name: 'SkeletonLoader',
    props: {
        animation: {
            type: String,
            default: 'wave',
            validate: v => ['fade', 'wave', 'pulseX', 'pulseY', 'pulse'].includes(v),
        },

        height: {
            type: [Number, String],
            default: '100%',
        },

        width: {
            type: [Number, String],
            default: '100%',
        },

        color: {
            type: String,
            default: '#f3f3f3',
        },

        waveColor: {
            type: String,
            default: 'rgba(255, 255, 255, .7)',
        },

        radius: {
            type: [Number, String],
            default: 0,
        },

        absolute: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        computedStyle() {
            return {
                width: convertToUnit(this.width, 'rem'),
                height: convertToUnit(this.height, 'rem'),
                backgroundColor: `${this.color}`,
                borderRadius: convertToUnit(this.radius, 'rem'),
            };
        },
    },

    mounted() {
        if (this.waveColor) {
            this.$el.style.setProperty('--skeleton-loader-gradient-color', this.waveColor);
        }
    },
};
</script>

<style lang="scss" module>
    .SkeletonLoader {
        position: relative;
        overflow: hidden;
        width: fit-content;
        cursor: wait;

        --skeleton-loader-gradient-color: rgba(255, 255, 255, .7);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
        }

        &._absolute {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &._fade {
            animation: fade 1.5s linear .5s infinite;
        }

        &._wave:before {
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .5), transparent);
            background: linear-gradient(90deg, transparent, var(--skeleton-loader-gradient-color), transparent);
            animation: wave 1.5s linear .5s infinite;
        }

        &._pulseX {
            animation: pulse-x 1.5s linear .5s infinite;
        }

        &._pulseY {
            animation: pulse-y 1.5s linear .5s infinite;
        }

        &._pulse {
            animation: pulse 1.5s linear .5s infinite;
        }
    }

    /* Animation definitions */
    @keyframes fade {
        0% {
            opacity: 1;
        }

        50% {
            opacity: .4;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(.85);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes pulse-x {
        0% {
            transform: scaleX(1);
        }

        50% {
            transform: scaleX(.75);
        }

        100% {
            transform: scaleX(1);
        }
    }

    @keyframes pulse-y {
        0% {
            transform: scaleY(1);
        }

        50% {
            transform: scaleY(.75);
        }

        100% {
            transform: scaleY(1);
        }
    }

    @keyframes wave {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(100%);
        }
    }
</style>
