// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheCompass_Xp9Yx{width:6.4rem;height:6.4rem;padding:.4rem}.TheCompass_Xp9Yx .container_hiFBs{position:relative;width:100%;height:100%}.TheCompass_Xp9Yx .arrow_3PXzH{position:absolute;top:50%;left:50%;width:2.4rem;height:2.4rem;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.TheCompass_Xp9Yx .icon_VFx5a{width:100%;height:100%;color:inherit}.TheCompass_Xp9Yx .side_843Rj{position:absolute;display:-webkit-box;display:-ms-flexbox;display:flex;width:1.2rem;height:1.2rem}.TheCompass_Xp9Yx .east_yR0mU{top:50%;right:0;color:#717171}.TheCompass_Xp9Yx .north_KQLNP{top:0;left:50%;color:#2f2f2f}.TheCompass_Xp9Yx .south_5NTsz{bottom:0;left:50%;color:#717171}.TheCompass_Xp9Yx .west_wb9SA{top:50%;left:0;color:#717171}@media(max-width:767.98px){.TheCompass_Xp9Yx{width:4.8rem;height:4.8rem;padding:.1rem}.TheCompass_Xp9Yx .arrow_3PXzH{width:2rem;height:2rem}.TheCompass_Xp9Yx .side_843Rj{width:1rem;height:1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TheCompass": "TheCompass_Xp9Yx",
	"container": "container_hiFBs",
	"arrow": "arrow_3PXzH",
	"icon": "icon_VFx5a",
	"side": "side_843Rj",
	"east": "east_yR0mU",
	"north": "north_KQLNP",
	"south": "south_5NTsz",
	"west": "west_wb9SA"
};
module.exports = ___CSS_LOADER_EXPORT___;
