<template>
    <div
        :style="`transform: rotate(${rotate}deg)`"
        :class="$style.TheCompass"
    >
        <div :class="$style.container">
            <ArrowIcon :class="$style.arrow" />

            <div
                :style="`transform: translateY(-50%) rotate(-${rotate}deg)`"
                :class="[$style.side, $style.east]"
            >
                <EastIcon :class="$style.icon" />
            </div>

            <div
                :style="`transform: translateX(-50%) rotate(-${rotate}deg)`"
                :class="[$style.side, $style.north]"
            >
                <NorthIcon :class="$style.icon" />
            </div>

            <div
                :style="`transform: translateX(-50%) rotate(-${rotate}deg)`"
                :class="[$style.side, $style.south]"
            >
                <SouthIcon :class="$style.icon" />
            </div>

            <div
                :style="`transform: translateY(-50%) rotate(-${rotate}deg)`"
                :class="[$style.side, $style.west]"
            >
                <WestIcon :class="$style.icon" />
            </div>
        </div>
    </div>
</template>

<script>
import ArrowIcon from '~/assets/icons/compass/arrow.svg?inline';
import EastIcon from '~/assets/icons/compass/east.svg?inline';
import NorthIcon from '~/assets/icons/compass/north.svg?inline';
import SouthIcon from '~/assets/icons/compass/south.svg?inline';
import WestIcon from '~/assets/icons/compass/west.svg?inline';

export default {
    name: 'TheCompass',

    components: {
        ArrowIcon,
        EastIcon,
        NorthIcon,
        SouthIcon,
        WestIcon,
    },

    props: {
        rotate: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss" module>
    .TheCompass {
        width: 6.4rem;
        height: 6.4rem;
        padding: .4rem;

        .container {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2.4rem;
            height: 2.4rem;
            transform: translate(-50%, -50%);
        }

        .icon {
            width: 100%;
            height: 100%;
            color: inherit;
        }

        .side {
            position: absolute;
            display: flex;
            width: 1.2rem;
            height: 1.2rem;
        }

        .east {
            top: 50%;
            right: 0;
            color: $base-500;
        }

        .north {
            top: 0;
            left: 50%;
            color: $base-900;
        }

        .south {
            bottom: 0;
            left: 50%;
            color: $base-500;
        }

        .west {
            top: 50%;
            left: 0;
            color: $base-500;
        }
    }

    @include respond-to(xs) {
        .TheCompass {
            width: 4.8rem;
            height: 4.8rem;
            padding: .1rem;

            .arrow {
                width: 2rem;
                height: 2rem;
            }

            .side {
                width: 1rem;
                height: 1rem;
            }
        }
    }
</style>
